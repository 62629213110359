<template>
	<div>
	<contentHeader title="Data Obat" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Table Obat</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-data-obat"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-6"></div>
	            				<div class="col-lg-4">
	            					<span>Cari Data</span>
	            					<input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
	            				</div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>	
							      <th scope="col" class="text-sm">KODE</th>
							      <th scope="col" class="text-sm">NAMA</th>
							      <th scope="col" class="text-sm">SATUAN BESAR</th>
							      <th scope="col" class="text-sm">SATUAN KECIL</th>
							      <th scope="col" class="text-sm">HARGA JUAL</th>
							      <th scope="col" class="text-sm" v-if="cabang.jenis == 'pusat'">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in data_result" :key="row.id">
							      <td class="text-sm" scope="row">{{ index+1 }}</td>
							      <td class="text-sm" scope="row">{{ row.kode }}</td>
							      <td class="text-sm" scope="row">{{ row.nama }}</td>
							      <td class="text-sm" scope="row">{{ row.satuan_besar }}</td>
							      <td class="text-sm" scope="row">{{ row.satuan }}</td>
							      <td class="text-sm" scope="row">{{ format_nominal(row.harga_jual) }}</td>
							      <td class="text-sm" v-if="cabang.jenis == 'pusat'">
							      	<div class="btn-group">
					                    <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
					                    <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
					                      <span class="sr-only"></span>
					                    </button>
					                    <div class="dropdown-menu" role="menu" style="">
					                      <router-link :to="`/edit-data-obat/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
					                      <div v-if="level_jabatan != 'staff'">
											<div class="dropdown-divider"></div>
											<button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger">Hapus</button>
										  </div>
					                    </div>
					                  </div>
							      </td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader'

	import { computed, ref, onMounted } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import format_nominal from '@/format_nominal.js'

	const data_result = ref([])
	const cek_data = ref(false)
	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const level_jabatan = computed(() => store.getters['auth/level_jabatan'])
	const memuat_data = ref(true)
	const page = ref(10)
	const links = ref([])
	const pagination = ref([])
	const kata_kunci = ref('')

	const getDataResult = async () => {
		data_result.value = []
		memuat_data.value = true
		cek_data.value = false

		let parameter = ''
		if (kata_kunci.value != '') {
			parameter = kata_kunci.value
		} else {
			parameter = 'emptyParameter'
		}

		let { data } = await axios.get(`api/produk/get_data/obat/${user.value.cabang_id}/${page.value}/${parameter}/${cabang.value.tipe}`)

		if (data != 'kosong') {
			memuat_data.value = false
			cek_data.value = false
			data_result.value = data.data
			links.value = data.links
			links.value.splice(0, 1)
			links.value.splice(links.value.length-1, 1)
			pagination.value = data
		} else {
			cek_data.value = true
			memuat_data.value = false
			data_result.value = []
		}
	}

	const konfirmasiHapus = (id) => {
		Swal.fire({
			title: 'Hapus Data?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: `Ya`,
			cancelButtonText: `Batal`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			hapusDataObat(id)
			} 
		})
	}

	const hapusDataObat = (id) => {
		axios.delete(`api/produk/destroy/${id}/${cabang.value.tipe}`)
		.then((response) => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil Menghapus Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})
			getDataResult()
			console.log(response.data)
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal menghapus data',
				icon: 'error',
				confirmButtonText: 'Ok'
			})
			console.log(error)
		})

		getDataResult()
	}

	const paginateData = async (url) => {
		if (url != null) {
			data_result.value = []
			memuat_data.value = true

			let link_url = new URL(url)
			link_url = `${link_url.pathname}${link_url.search}`
			
			let { data } = await axios.get(`${link_url}`)

			
			if (data != 'kosong') {
				memuat_data.value = false
				data_result.value = data.data	
				links.value = data.links
				links.value.splice(0, 1)
				links.value.splice(links.value.length-1, 1)
				pagination.value = data
			}
		}
	}

	onMounted(() => {
		getDataResult()
	})
</script>